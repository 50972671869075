<template>
  <div id="agents-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" class="pl-0 config-breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click.native="!item.disabled && $router.push(item.href)" class="primary--text"
          :class="{'secondary--text': breadcrumbList.length > 1 && item.disabled}">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <agent-form v-if="agentId" />
    <agent-home v-else />
  </div>
</template>

<script>
export default {
  name: "Agents",
  metaInfo: {
    title: "Agents",
  },
  components: {
    agentForm: () => import("../agents/components/AgentForm"),
    agentHome: () => import("../agents/components/AgentHome"),
  },
  data: (vm) => ({
      agentId: vm.$route.params.agentId,
      breadcrumbList: [{
        text: 'Agents',
        disabled: true,
        href: '/agents',
      }]
  }),
  watch: {
    $route(newVal) {
      this.agentId = newVal.params.agentId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.agentId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.agentId == 'create' ? 'Create' : 'Update'} Agent`,
          disabled: true,
          href: `/agents/${this.agentId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Agents',
          disabled: true,
          href: '/agents',
        }]
      }
    }
  }
};
</script>
